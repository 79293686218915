import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding-bottom: 100px;

  ${t.H1} {
    margin-bottom: 25px;
  }
  ${t.H4} {
    line-height: 1.14;
  }
  ${media.tablet`background-position: center top 0px;`};
`;

const AboutMeWrapper = styled.div`
  ${Mixins.wrapper}
  .m-b-60 {
    margin-bottom: 60px;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    min-height: 540px;
    ${media.phone`min-height: 620px;`};
  }
  .gatsby-image {
    ${media.tablet`text-align: center;`}
    div {
      padding: 0;
    }
    picture img {
      max-width: 85%;
      position: relative;
      ${media.tablet`max-width: 80%;`}
    }
  }
  .avatar {
    max-width: 400px;
    width: 80%;
    margin: 0 auto 100px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
`;

class AboutMe extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <AboutMeWrapper>
        <Layout theme="white" openContactPopup={this.openContactPopup}>
          <AboveFold>
            <t.H1 green align="center">
              Santiago Hyun Dorado
            </t.H1>
            <t.LargeP align="center" max70>
              Soy ingeniero de sistemas egresado de la Universidad del Cauca. Tengo experiencia en proyectos de
              desarrollo de aplicaciones web. He participado en varios proyectos de desarrollo de productos para gestión
              de información en diferentes contextos de negocio. Actualmente trabajo como ingeniero de producto en{' '}
              <strong>Codescrum</strong>, donde desempeño labores de desarrollo, DevOps e infraestructura en la nube de
              AWS. Me gusta indagar sobre tendencias en tecnología, investigación e innovación, específicamente en areas
              relacionadas con la arquitectura del software
            </t.LargeP>
          </AboveFold>
          <Content>
            <Img fluid={data.avatarAbout.childImageSharp.fluid} alt="Santiago Hyun Dorado" className="avatar" />
            <t.H2 align="center">A nivel académico</t.H2>
            <t.LargeP max70>
              Egresado en Ingeniería de Sistemas de la Universidad del Cauca y miembro activo del semillero de
              investigación del grupo IDIS de esta misma universidad. Mientras estuve como estudiante de pregrado tuve
              la oportunidad de representar a la Universidad del Cauca en uno de los eventos más importantes de Colombia
              en materia de ciencias de la informática y computación, el 13CCC (Congreso Colombiano de Computación)
              donde presenté uno de los artículos resultantes de mi trabajo de grado titulado "Una herramienta software
              basada en anotaciones de código para documentar el Rationale arquitectónico". El otro artículo resultante
              de este trabajo de grado fue presentado en la versión 28 de la "International Conference on Software
              Engineering and Data Engineering" realizada en San Diego California.
            </t.LargeP>
            <t.P max70>
              <a href="https://easychair.org/publications/paper/pcP5 " target="_blank" rel="noopener noreferrer">
                Artículo
              </a>
              <br />
              <a href="https://zahydo.github.io/arat-V1.0/" target="_blank" rel="noopener noreferrer">
                ARAT: Architectural Rationale Annotations Tool
              </a>
            </t.P>
            <t.H2 align="center">A nivel profesional</t.H2>
            <t.LargeP max70>
              En los últimos semestres de mi carrera, empecé con mi experiencia laboral trabajando en Sitis s.a.s,
              empresa de desarrollo de software dedicada al sector de la salud, puntualmente en el aseguramiento, la
              gestión de pólizas, diagnósticos, eventos programados, ingresos hostpitalarios, etc. En esta empresa
              aprendí diferentes habilidades técnicas y humanas que me brindaron opciones para enrutar mi camino
              profesional. Actualmente me encuentro trabajando en Codescrum, empresa dedicada al desarrollo de software
              a la medida y en la cual también he tenido la oportunidad de aprender nuevas tecnologías, tendencias,
              herramientas, procesos, y valores como la precisión, el cambio constante y el real entendimiento.
              <br />
              Estas son algunas de las habilidades técnicas que he podido aprender dentro de mi carrera profesional:
              <ul>
                <li>
                  Amazon Web Services: Lambda, DybamoDB, ApiGateway, Ec2, Amplify platform, CDK, SES, Route53, IAM,
                  CloudFormation, etc.
                </li>
                <li>Desarrollo de aplicaciones Frontend con: ReactJS.</li>
                <li>
                  Desarrollo de aplicaciones Backend en diferentes lenguajes de programación: Ruby, Java, Javascript y
                  Python.
                </li>
                <li>Desarrollo de APIs basadas en REST y GraphQL</li>
                <li>
                  Manejo de herramientas de entrega e integración continua: Jenkins, Rundeck, GitLab, Netlify y otros
                  recursos de AWS como: CodePipeline.
                </li>
                <li>Gestión de contenedores con Docker, docker-compose y docker-machine.</li>
              </ul>
              Las aplicaciones web que puedo desarrollar estan diseñadas para cumplir con los siguientes requerimientos:
              <ul>
                <li>Configuración SEO</li>
                <li>Imágenes responsive (Adaptables en diferentes dispositivos)</li>
                <li>Carga de imágenes progresiva</li>
                <li>Soporte offline</li>
                <li>Integración con Google analytics</li>
                <li>Características Serverless</li>
                <li>Protección de XSS</li>
                <li>Control de acceso (CORS)</li>
                <li>Autenticación de usuarios</li>
                <li>Escalabilidad</li>
                <li>Interoperabilidad</li>
                <li>Usabilidad</li>
              </ul>
            </t.LargeP>
          </Content>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </AboutMeWrapper>
    );
  }
}

export default AboutMe;

export const pageQuery = graphql`
  query {
    avatarAbout: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
  }
`;
